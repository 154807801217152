<template>
  <div style="padding: 20px">
    <h2>（一）手术加急权益服务</h2>
    <p>
      <u><strong>【服务特点】</strong></u>
    </p>
    <p>快速响应、专业保障、全程服务</p>
    <p>
      <u><strong>【什么是手术加急服务】</strong></u>
    </p>
    <p>
      手术加急服务是一项旨在为用户提供快速、高效医疗服务的特色服务。这项服务允许用户在需要手术时进行加急预约，以缩短等待时间并尽快接受手术治疗。
    </p>
    <p>
      <u><strong>【权益说明】</strong></u>
    </p>
    <p>
      (1) &nbsp; &nbsp;
      收到用户提供的住院单需求后，在7个工作日，为服务对象落实预约住院及手术服务。
    </p>
    <p>
      (2) &nbsp; &nbsp;
      限车主本人120种重大疾病使用，30天激活等待期，拿到二级及以上公立医院诊断后可以发起服务申请
    </p>
    <p>(3) &nbsp; &nbsp; 车主权益券不可叠加使用，不可拆分，不兑现。</p>
    <p></p>
    <h2>附录：120种重大疾病</h2>
    <table style="width: 100%">
      <tbody>
        <tr>
          <th colSpan="6" rowSpan="1" width="100%">重大疾病列表（120种）</th>
        </tr>
        <tr>
          <td colSpan="1" rowSpan="1" width="4%">1</td>
          <td colSpan="1" rowSpan="1" width="30%">恶性肿瘤</td>
          <td colSpan="1" rowSpan="1" width="4%">41</td>
          <td colSpan="1" rowSpan="1" width="28%">持续植物人状态</td>
          <td colSpan="1" rowSpan="1" width="5%">81</td>
          <td colSpan="1" rowSpan="1" width="26%">肾髓质囊性病</td>
        </tr>
        <tr>
          <td colSpan="1" rowSpan="1" width="4%">2</td>
          <td colSpan="1" rowSpan="1" width="30%">急性心肌梗塞</td>
          <td colSpan="1" rowSpan="1" width="4%">42</td>
          <td colSpan="1" rowSpan="1" width="28%">坏死性筋膜炎</td>
          <td colSpan="1" rowSpan="1" width="5%">82</td>
          <td colSpan="1" rowSpan="1" width="26%">肝豆状核变性</td>
        </tr>
        <tr>
          <td colSpan="1" rowSpan="1" width="4%">3</td>
          <td colSpan="1" rowSpan="1" width="30%">脑中风后遗症</td>
          <td colSpan="1" rowSpan="1" width="4%">43</td>
          <td colSpan="1" rowSpan="1" width="28%">嗜铬细胞瘤</td>
          <td colSpan="1" rowSpan="1" width="5%">83</td>
          <td colSpan="1" rowSpan="1" width="26%">
            急性出血坏死性胰腺炎开腹手术
          </td>
        </tr>
        <tr>
          <td colSpan="1" rowSpan="1" width="4%">4</td>
          <td colSpan="1" rowSpan="1" width="30%">
            重大器官移植术或造血干细胞移植术
          </td>
          <td colSpan="1" rowSpan="1" width="4%">44</td>
          <td colSpan="1" rowSpan="1" width="28%">象皮病</td>
          <td colSpan="1" rowSpan="1" width="5%">84</td>
          <td colSpan="1" rowSpan="1" width="26%">小肠移植</td>
        </tr>
        <tr>
          <td colSpan="1" rowSpan="1" width="4%">5</td>
          <td colSpan="1" rowSpan="1" width="30%">冠状动脉搭桥术</td>
          <td colSpan="1" rowSpan="1" width="4%">45</td>
          <td colSpan="1" rowSpan="1" width="28%">胰腺移植术</td>
          <td colSpan="1" rowSpan="1" width="5%">85</td>
          <td colSpan="1" rowSpan="1" width="26%">重症手足口病</td>
        </tr>
        <tr>
          <td colSpan="1" rowSpan="1" width="4%">6</td>
          <td colSpan="1" rowSpan="1" width="30%">终末期肾病</td>
          <td colSpan="1" rowSpan="1" width="4%">46</td>
          <td colSpan="1" rowSpan="1" width="28%">
            特发性慢性肾上腺皮质功能减退
          </td>
          <td colSpan="1" rowSpan="1" width="5%">86</td>
          <td colSpan="1" rowSpan="1" width="26%">严重的结核性脊髓炎</td>
        </tr>
        <tr>
          <td colSpan="1" rowSpan="1" width="4%">7</td>
          <td colSpan="1" rowSpan="1" width="30%">多个肢体缺失</td>
          <td colSpan="1" rowSpan="1" width="4%">47</td>
          <td colSpan="1" rowSpan="1" width="28%">严重心肌炎</td>
          <td colSpan="1" rowSpan="1" width="5%">87</td>
          <td colSpan="1" rowSpan="1" width="26%">溶血性链球菌引起的坏疽</td>
        </tr>
        <tr>
          <td colSpan="1" rowSpan="1" width="4%">8</td>
          <td colSpan="1" rowSpan="1" width="30%">急性或亚急性重症肝炎</td>
          <td colSpan="1" rowSpan="1" width="4%">48</td>
          <td colSpan="1" rowSpan="1" width="28%">肺源性心脏病</td>
          <td colSpan="1" rowSpan="1" width="5%">88</td>
          <td colSpan="1" rowSpan="1" width="26%">
            因器官移植原因导致的感染艾滋病病毒或患艾滋病
          </td>
        </tr>
        <tr>
          <td colSpan="1" rowSpan="1" width="4%">9</td>
          <td colSpan="1" rowSpan="1" width="30%">良性脑肿瘤</td>
          <td colSpan="1" rowSpan="1" width="4%">49</td>
          <td colSpan="1" rowSpan="1" width="28%">原发性硬化性胆管炎</td>
          <td colSpan="1" rowSpan="1" width="5%">89</td>
          <td colSpan="1" rowSpan="1" width="26%">埃博拉病毒感染</td>
        </tr>
        <tr>
          <td colSpan="1" rowSpan="1" width="4%">10</td>
          <td colSpan="1" rowSpan="1" width="30%">慢性肝功能衰竭失代偿期</td>
          <td colSpan="1" rowSpan="1" width="4%">50</td>
          <td colSpan="1" rowSpan="1" width="28%">严重自身免疫性肝炎</td>
          <td colSpan="1" rowSpan="1" width="5%">90</td>
          <td colSpan="1" rowSpan="1" width="26%">成骨不全症Ⅲ型</td>
        </tr>
        <tr>
          <td colSpan="1" rowSpan="1" width="4%">11</td>
          <td colSpan="1" rowSpan="1" width="30%">脑炎后遗症或脑膜炎后遗症</td>
          <td colSpan="1" rowSpan="1" width="4%">51</td>
          <td colSpan="1" rowSpan="1" width="28%">原发性骨髓纤维化（PMF）</td>
          <td colSpan="1" rowSpan="1" width="5%">91</td>
          <td colSpan="1" rowSpan="1" width="26%">严重川崎病</td>
        </tr>
        <tr>
          <td colSpan="1" rowSpan="1" width="4%">12</td>
          <td colSpan="1" rowSpan="1" width="30%">深度昏迷</td>
          <td colSpan="1" rowSpan="1" width="4%">52</td>
          <td colSpan="1" rowSpan="1" width="28%">重症骨髓增生异常综合征</td>
          <td colSpan="1" rowSpan="1" width="5%">92</td>
          <td colSpan="1" rowSpan="1" width="26%">严重幼年型类风湿性关节炎</td>
        </tr>
        <tr>
          <td colSpan="1" rowSpan="1" width="4%">13</td>
          <td colSpan="1" rowSpan="1" width="30%">双耳失聪</td>
          <td colSpan="1" rowSpan="1" width="4%">53</td>
          <td colSpan="1" rowSpan="1" width="28%">自体造血干细胞移植术</td>
          <td colSpan="1" rowSpan="1" width="5%">93</td>
          <td colSpan="1" rowSpan="1" width="26%">疾病或外伤所致智力障碍</td>
        </tr>
        <tr>
          <td colSpan="1" rowSpan="1" width="4%">14</td>
          <td colSpan="1" rowSpan="1" width="30%">双目失明</td>
          <td colSpan="1" rowSpan="1" width="4%">54</td>
          <td colSpan="1" rowSpan="1" width="28%">Brugada综合征</td>
          <td colSpan="1" rowSpan="1" width="5%">94</td>
          <td colSpan="1" rowSpan="1" width="26%">严重肠道疾病并发症</td>
        </tr>
        <tr>
          <td colSpan="1" rowSpan="1" width="4%">15</td>
          <td colSpan="1" rowSpan="1" width="30%">瘫痪</td>
          <td colSpan="1" rowSpan="1" width="4%">55</td>
          <td colSpan="1" rowSpan="1" width="28%">严重急性主动脉夹层血肿</td>
          <td colSpan="1" rowSpan="1" width="5%">95</td>
          <td colSpan="1" rowSpan="1" width="26%">严重瑞氏综合征</td>
        </tr>
        <tr>
          <td colSpan="1" rowSpan="1" width="4%">16</td>
          <td colSpan="1" rowSpan="1" width="30%">心脏瓣膜手术</td>
          <td colSpan="1" rowSpan="1" width="4%">56</td>
          <td colSpan="1" rowSpan="1" width="28%">严重慢性缩窄型心包炎</td>
          <td colSpan="1" rowSpan="1" width="5%">96</td>
          <td colSpan="1" rowSpan="1" width="26%">
            急性肺损伤（ALI）或急性呼吸窘迫综合征（ARDS）
          </td>
        </tr>
        <tr>
          <td colSpan="1" rowSpan="1" width="4%">17</td>
          <td colSpan="1" rowSpan="1" width="30%">严重阿尔茨海默病</td>
          <td colSpan="1" rowSpan="1" width="4%">57</td>
          <td colSpan="1" rowSpan="1" width="28%">心脏粘液瘤</td>
          <td colSpan="1" rowSpan="1" width="5%">97</td>
          <td colSpan="1" rowSpan="1" width="26%">溶血性尿毒综合征</td>
        </tr>
        <tr>
          <td colSpan="1" rowSpan="1" width="4%">18</td>
          <td colSpan="1" rowSpan="1" width="30%">严重脑损伤</td>
          <td colSpan="1" rowSpan="1" width="4%">58</td>
          <td colSpan="1" rowSpan="1" width="28%">严重心脏衰竭CRT治疗</td>
          <td colSpan="1" rowSpan="1" width="5%">98</td>
          <td colSpan="1" rowSpan="1" width="26%">出血性登革热</td>
        </tr>
        <tr>
          <td colSpan="1" rowSpan="1" width="4%">19</td>
          <td colSpan="1" rowSpan="1" width="30%">严重帕金森病</td>
          <td colSpan="1" rowSpan="1" width="4%">59</td>
          <td colSpan="1" rowSpan="1" width="28%">完全性房室传导阻滞</td>
          <td colSpan="1" rowSpan="1" width="5%">99</td>
          <td colSpan="1" rowSpan="1" width="26%">严重癫痫症</td>
        </tr>
        <tr>
          <td colSpan="1" rowSpan="1" width="4%">20</td>
          <td colSpan="1" rowSpan="1" width="30%">严重Ⅲ度烧伤</td>
          <td colSpan="1" rowSpan="1" width="4%">60</td>
          <td colSpan="1" rowSpan="1" width="28%">风湿热导致的心脏瓣膜病变</td>
          <td colSpan="1" rowSpan="1" width="5%">100</td>
          <td colSpan="1" rowSpan="1" width="26%">左室室壁瘤切除手术</td>
        </tr>
        <tr>
          <td colSpan="1" rowSpan="1" width="4%">21</td>
          <td colSpan="1" rowSpan="1" width="30%">严重原发性肺动脉高压</td>
          <td colSpan="1" rowSpan="1" width="4%">61</td>
          <td colSpan="1" rowSpan="1" width="28%">
            多发性大动脉炎旁路移植手术
          </td>
          <td colSpan="1" rowSpan="1" width="5%">101</td>
          <td colSpan="1" rowSpan="1" width="26%">侵蚀性葡萄胎</td>
        </tr>
        <tr>
          <td colSpan="1" rowSpan="1" width="4%">22</td>
          <td colSpan="1" rowSpan="1" width="30%">严重运动神经元病</td>
          <td colSpan="1" rowSpan="1" width="4%">62</td>
          <td colSpan="1" rowSpan="1" width="28%">肺淋巴管肌瘤病</td>
          <td colSpan="1" rowSpan="1" width="5%">102</td>
          <td colSpan="1" rowSpan="1" width="26%">严重感染性心内膜炎</td>
        </tr>
        <tr>
          <td colSpan="1" rowSpan="1" width="4%">23</td>
          <td colSpan="1" rowSpan="1" width="30%">语言能力丧失</td>
          <td colSpan="1" rowSpan="1" width="4%">63</td>
          <td colSpan="1" rowSpan="1" width="28%">严重肺结节病</td>
          <td colSpan="1" rowSpan="1" width="5%">103</td>
          <td colSpan="1" rowSpan="1" width="26%">
            肺泡蛋白质沉积症全肺灌洗治疗
          </td>
        </tr>
        <tr>
          <td colSpan="1" rowSpan="1" width="4%">24</td>
          <td colSpan="1" rowSpan="1" width="30%">重型再生障碍性贫血</td>
          <td colSpan="1" rowSpan="1" width="4%">64</td>
          <td colSpan="1" rowSpan="1" width="28%">
            非阿尔茨海默病所致严重痴呆
          </td>
          <td colSpan="1" rowSpan="1" width="5%">104</td>
          <td colSpan="1" rowSpan="1" width="26%">严重面部烧伤</td>
        </tr>
        <tr>
          <td colSpan="1" rowSpan="1" width="4%">25</td>
          <td colSpan="1" rowSpan="1" width="30%">主动脉手术</td>
          <td colSpan="1" rowSpan="1" width="4%">65</td>
          <td colSpan="1" rowSpan="1" width="28%">进行性核上性麻痹</td>
          <td colSpan="1" rowSpan="1" width="5%">105</td>
          <td colSpan="1" rowSpan="1" width="26%">失去一肢及一眼</td>
        </tr>
        <tr>
          <td colSpan="1" rowSpan="1" width="4%">26</td>
          <td colSpan="1" rowSpan="1" width="30%">严重原发性心肌病</td>
          <td colSpan="1" rowSpan="1" width="4%">66</td>
          <td colSpan="1" rowSpan="1" width="28%">克雅氏病</td>
          <td colSpan="1" rowSpan="1" width="5%">106</td>
          <td colSpan="1" rowSpan="1" width="26%">严重哮喘</td>
        </tr>
        <tr>
          <td colSpan="1" rowSpan="1" width="4%">27</td>
          <td colSpan="1" rowSpan="1" width="30%">终末期肺病</td>
          <td colSpan="1" rowSpan="1" width="4%">67</td>
          <td colSpan="1" rowSpan="1" width="28%">亚急性硬化性全脑炎</td>
          <td colSpan="1" rowSpan="1" width="5%">107</td>
          <td colSpan="1" rowSpan="1" width="26%">严重大动脉炎</td>
        </tr>
        <tr>
          <td colSpan="1" rowSpan="1" width="4%">28</td>
          <td colSpan="1" rowSpan="1" width="30%">严重多发性硬化</td>
          <td colSpan="1" rowSpan="1" width="4%">68</td>
          <td colSpan="1" rowSpan="1" width="28%">进行性多灶性白质脑病</td>
          <td colSpan="1" rowSpan="1" width="5%">108</td>
          <td colSpan="1" rowSpan="1" width="26%">艾森门格综合征</td>
        </tr>
        <tr>
          <td colSpan="1" rowSpan="1" width="4%">29</td>
          <td colSpan="1" rowSpan="1" width="30%">
            因职业关系导致的感染艾滋病病毒或患艾滋病
          </td>
          <td colSpan="1" rowSpan="1" width="4%">69</td>
          <td colSpan="1" rowSpan="1" width="28%">丧失独立生活能力</td>
          <td colSpan="1" rowSpan="1" width="5%">109</td>
          <td colSpan="1" rowSpan="1" width="26%">严重结核性脑膜炎</td>
        </tr>
        <tr>
          <td colSpan="1" rowSpan="1" width="4%">30</td>
          <td colSpan="1" rowSpan="1" width="30%">严重溃疡性结肠炎</td>
          <td colSpan="1" rowSpan="1" width="4%">70</td>
          <td colSpan="1" rowSpan="1" width="28%">脊髓小脑变性症</td>
          <td colSpan="1" rowSpan="1" width="5%">110</td>
          <td colSpan="1" rowSpan="1" width="26%">急性弥漫性血管内凝血</td>
        </tr>
        <tr>
          <td colSpan="1" rowSpan="1" width="4%">31</td>
          <td colSpan="1" rowSpan="1" width="30%">重症肌无力</td>
          <td colSpan="1" rowSpan="1" width="4%">71</td>
          <td colSpan="1" rowSpan="1" width="28%">神经白塞病</td>
          <td colSpan="1" rowSpan="1" width="5%">111</td>
          <td colSpan="1" rowSpan="1" width="26%">
            败血症导致的多器官功能障碍综合症
          </td>
        </tr>
        <tr>
          <td colSpan="1" rowSpan="1" width="4%">32</td>
          <td colSpan="1" rowSpan="1" width="30%">严重类风湿性关节炎</td>
          <td colSpan="1" rowSpan="1" width="4%">72</td>
          <td colSpan="1" rowSpan="1" width="28%">脊髓内肿瘤</td>
          <td colSpan="1" rowSpan="1" width="5%">112</td>
          <td colSpan="1" rowSpan="1" width="26%">范可尼综合征</td>
        </tr>
        <tr>
          <td colSpan="1" rowSpan="1" width="4%">33</td>
          <td colSpan="1" rowSpan="1" width="30%">脊髓灰质炎</td>
          <td colSpan="1" rowSpan="1" width="4%">73</td>
          <td colSpan="1" rowSpan="1" width="28%">亚历山大病</td>
          <td colSpan="1" rowSpan="1" width="5%">113</td>
          <td colSpan="1" rowSpan="1" width="26%">严重继发性肺动脉高压</td>
        </tr>
        <tr>
          <td colSpan="1" rowSpan="1" width="4%">34</td>
          <td colSpan="1" rowSpan="1" width="30%">
            经输血导致的感染艾滋病病毒或患艾滋病
          </td>
          <td colSpan="1" rowSpan="1" width="4%">74</td>
          <td colSpan="1" rowSpan="1" width="28%">脊髓空洞症</td>
          <td colSpan="1" rowSpan="1" width="5%">114</td>
          <td colSpan="1" rowSpan="1" width="26%">严重气性坏疽</td>
        </tr>
        <tr>
          <td colSpan="1" rowSpan="1" width="4%">35</td>
          <td colSpan="1" rowSpan="1" width="30%">严重克隆病</td>
          <td colSpan="1" rowSpan="1" width="4%">75</td>
          <td colSpan="1" rowSpan="1" width="28%">脊髓血管病后遗症</td>
          <td colSpan="1" rowSpan="1" width="5%">115</td>
          <td colSpan="1" rowSpan="1" width="26%">进行性风疹性全脑炎</td>
        </tr>
        <tr>
          <td colSpan="1" rowSpan="1" width="4%">36</td>
          <td colSpan="1" rowSpan="1" width="30%">肌营养不良症</td>
          <td colSpan="1" rowSpan="1" width="4%">76</td>
          <td colSpan="1" rowSpan="1" width="28%">颅脑手术</td>
          <td colSpan="1" rowSpan="1" width="5%">116</td>
          <td colSpan="1" rowSpan="1" width="26%">多处臂丛神经根性撕脱</td>
        </tr>
        <tr>
          <td colSpan="1" rowSpan="1" width="4%">37</td>
          <td colSpan="1" rowSpan="1" width="30%">破裂脑动脉瘤夹闭手术</td>
          <td colSpan="1" rowSpan="1" width="4%">77</td>
          <td colSpan="1" rowSpan="1" width="28%">
            系统性红斑狼疮并发重度的肾功能损害
          </td>
          <td colSpan="1" rowSpan="1" width="5%">117</td>
          <td colSpan="1" rowSpan="1" width="26%">皮质基底节变性</td>
        </tr>
        <tr>
          <td colSpan="1" rowSpan="1" width="4%">38</td>
          <td colSpan="1" rowSpan="1" width="30%">系统性硬皮病</td>
          <td colSpan="1" rowSpan="1" width="4%">78</td>
          <td colSpan="1" rowSpan="1" width="28%">严重Ｉ型糖尿病</td>
          <td colSpan="1" rowSpan="1" width="5%">118</td>
          <td colSpan="1" rowSpan="1" width="26%">糖尿病导致双足截除</td>
        </tr>
        <tr>
          <td colSpan="1" rowSpan="1" width="4%">39</td>
          <td colSpan="1" rowSpan="1" width="30%">严重冠心病</td>
          <td colSpan="1" rowSpan="1" width="4%">79</td>
          <td colSpan="1" rowSpan="1" width="28%">席汉氏综合征</td>
          <td colSpan="1" rowSpan="1" width="5%">119</td>
          <td colSpan="1" rowSpan="1" width="26%">闭锁综合征</td>
        </tr>
        <tr>
          <td colSpan="1" rowSpan="1" width="4%">40</td>
          <td colSpan="1" rowSpan="1" width="30%">严重慢性复发性胰腺炎</td>
          <td colSpan="1" rowSpan="1" width="4%">80</td>
          <td colSpan="1" rowSpan="1" width="28%">严重强直性脊柱炎</td>
          <td colSpan="1" rowSpan="1" width="5%">120</td>
          <td colSpan="1" rowSpan="1" width="26%">严重巨细胞动脉炎</td>
        </tr>
      </tbody>
    </table>
    <p><br /></p>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
